(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/link/views/link.js') >= 0) return;  svs.modules.push('/components/banner_actions/link/views/link.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_actions=_svs.banner_actions||{};
_svs.banner_actions.link=_svs.banner_actions.link||{};
_svs.banner_actions.link.templates=_svs.banner_actions.link.templates||{};
svs.banner_actions.link.templates.link = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"renderCrmAttributes")||(depth0 && lookupProperty(depth0,"renderCrmAttributes"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"crm") : depth0),{"name":"renderCrmAttributes","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":41}}}));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"linkTarget") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "_self";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"linkUrl") : stack1), depth0))
    + "\"\n   data-click-tracking=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"clickTrackingString") || (depth0 != null ? lookupProperty(depth0,"clickTrackingString") : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"clickTrackingString","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":47}}}) : helper)))
    + "\"\n   "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"crm") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":3},"end":{"line":3,"column":48}}})) != null ? stack1 : "")
    + "\n   target=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"action") : depth0)) != null ? lookupProperty(stack1,"linkTarget") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":77}}})) != null ? stack1 : "")
    + "\"\n   rel=\""
    + alias1((lookupProperty(helpers,"getRel")||(depth0 && lookupProperty(depth0,"getRel"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"action") : depth0),{"name":"getRel","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":25}}}))
    + "\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>\n";
},"usePartial":true,"useData":true});
Handlebars.partials['components-banner_actions-link-link'] = svs.banner_actions.link.templates.link;
})(svs, Handlebars);


 })(window);